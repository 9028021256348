<template>
  <div class="container-md col-lg-11 col-md-11 review-activity pt-2">
    <div class="d-flex">
      <h4 class="text-primary w-100">
        {{ "Candidate Interview" }}
      </h4>
      <CButton
        class="justify-content-end text-nowrap"
        color="primary"
        shape="pill"
        @click="backToList"
        >Back to list</CButton
      >
    </div>
    <div class="mt-3 mb-3">
      <InterviewActivity
        v-if="isPendingActivity"
        :actionDetail="selectedActivity"
        :comments="getComments"
        @changeActivityStatus="changeActivityStatus"
        @openCandidateInfoModal="openCandidateInfoModal"
        @openJobInfoModal="openJobInfoModal"
      />
      <h4 v-else class="text-muted p-4 text-center">
        No Pending Activity Available for Review
      </h4>
    </div>
    <CandidateInfoModal
      v-if="candidateInfoModal.isShowPopup"
      :candidate_uid="candidateInfoModal.candidate_uid"
      :isShowModal="candidateInfoModal.isShowPopup"
      :isShowContact="false"
      :enableAddScratchPad="false"
      @modalCallBack="candidateInfoModalCallBack"
    />
    <JobInfoModal
      v-if="jobInfoModal.isShowPopup"
      :job_id="jobInfoModal.job_id"
      :isShowModal="jobInfoModal.isShowPopup"
      @modalCallBack="jobInfoModalCallBack"
    />
  </div>
</template>
<script>
import InterviewActivity from "@/containers/ActivityList/CandidateInterviewer/InterviewActivity";
import CompletedCandidateReReview from "@/containers/ActivityList/CandidateInterviewer/CompletedCandidateReReview.vue";
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper.js";
import CandidateInfoModal from "@/components/reusable/CandidateInfoModal";
import JobInfoModal from "@/components/reusable/JobInfoModal";
export default {
  components: {
    InterviewActivity,
    CompletedCandidateReReview,
    CandidateInfoModal,
    JobInfoModal,
  },
  data() {
    return {
      candidateInfoModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
      jobInfoModal: {
        isShowPopup: false,
        job_id: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "selectedActivity",
      "getComments",
      "activeSubTab",
      "activeTab",
      "currentUserType",
    ]),
    isPendingActivity() {
      return (
        isObject(this.selectedActivity) &&
        !isEmptyObjectCheck(this.selectedActivity) &&
        [54].includes(this.selectedActivity.status_id) &&
        [51, 55].includes(this.selectedActivity.sub_status_id)
      );
    },
    isCompletedActivity() {
      return (
        isObject(this.selectedActivity) &&
        !isEmptyObjectCheck(this.selectedActivity) &&
        [54].includes(this.selectedActivity.status_id) &&
        [57, null].includes(this.selectedActivity.sub_status_id)
      );
    },
  },
  methods: {
    ...mapActions([
      "candidateInterviewSchedule",
      "fetchSelectedJobActivity",
      "fetchSelectedActivityComments",
    ]),
    changeActivityStatus(payload) {
      this.candidateInterviewSchedule(payload);
    },
    backToList() {
      // this.isPendingActivity
      //   ? this.$store.commit("SET_ACTIVE_TAB", 0)
      //   : this.$store.commit("SET_ACTIVE_TAB", 1);
      const currentUserType = this.currentUserType;
      let params = {};
      if (currentUserType?.code === "customer_recruiter") {
        params = { user_type: 2 };
      }
      this.$store.commit("SET_ACTIVE_TAB", this.activeTab);
      this.$store.commit("SET_ACTIVE_SUB_TAB", this.activeSubTab);
      this.$router.push({ path: `/list-activity`, query: params });
    },
    openCandidateInfoModal(candidate_uid) {
      if (candidate_uid) {
        this.candidateInfoModal.candidate_uid = candidate_uid;
        this.candidateInfoModal.isShowPopup = true;
      }
    },
    openJobInfoModal(job_id) {
      if (job_id) {
        this.jobInfoModal.job_id = job_id;
        this.jobInfoModal.isShowPopup = true;
      }
    },
    candidateInfoModalCallBack(action) {
      this.candidateInfoModal.isShowPopup = false;
    },
    jobInfoModalCallBack(action) {
      this.jobInfoModal.isShowPopup = false;
    },
  },
  async mounted() {
    const {
      params: { jobID, candidateID },
    } = this.$route;
    let job_id = jobID ? parseInt(jobID) : null;
    let candidate_uid = candidateID ? parseInt(candidateID) : null;
    this.fetchSelectedJobActivity({
      job_id,
      candidate_uid,
      action_id: 18,
      status_id__in: [54],
      sub_status_id__in: [57, 51, 55, "null"],
    });
    this.fetchSelectedActivityComments({
      job_id,
      candidate_uid,
      action_id: 18,
      status_id__in: [54],
      sub_status_id__in: [57, 51, 55, "null"],
    });
  },
};
</script>